export const DATE_FORMAT = "YYYY-MM-DDTHH:mm";

export const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const PLATFORM_INTRO_COOKIE_KEY = "wtIntroSkip";

export enum TRADE_OPERATION_TYPE {
    BUY = "Buy",
    SELL = "Sell",
}

export const GUEST_FAVOURITES_STORAGE_KEY = "trader_guest_favourites";

export const MARGIN_CALL_COOKIE_KEY = "trader_margin_call_shown";

export const SYMBOLS_IMAGES_MAP_STORAGE_KEY = "symbols_images_map";
