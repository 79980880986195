import { MutableRefObject } from "react";
import { getSeenPrice } from "@/utils/getSeenPrice";
import { getLocationParam } from "./url";

export const setOperationTimestamp = (): void => {
    const timestamp = new Date().getTime();

    localStorage.setItem("operationStartTimestamp", `${timestamp}`);
};

export const hasElementScroll = (ref: MutableRefObject<HTMLDivElement>): boolean => {
    return ref.current?.scrollHeight > ref.current?.clientHeight;
};

export const capitalizeFirstLetter = (value: string): string => {
    return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
};

export const getBrandIconName = (mode?: string): string => {
    const brand = window?.location.host?.split(".")[1];

    switch (brand) {
        case "investous":
            return "investous.svg";
        case "forextb":
        case "fxtb":
            return "fxtb.svg";
        case "investmarkets":
            return "investmarkets.svg";
        case "invesacapital":
            return "invesacapital.svg";
        case "inefex":
            return "inefex.svg";
        case "obrinvest":
            return "obrinvest.svg";
        case "patronfx":
            return "patronfx.svg";
        case "liquidityx":
            return "liquidity.svg";
        case "investico":
            return "investico.svg";
        case "bigmarkets":
            return "bigmarkets.svg";
        case "bullmarkets":
            return "bullmarkets.svg";
        case "market10":
            return `market_10_${mode}.svg`;
        case "fxonet":
            return `fxonet.svg`;
        case "proxtrend":
            return `proxtrend.svg`;
        default:
            return `default.svg`;
    }
};

export const getBrandName = (): string => {
    const brand = window?.location.host?.split(".")[1];

    switch (brand) {
        case "investous":
            return "Investous";
        case "forextb":
        case "fxtb":
            return "ForexTB";
        case "investmarkets":
            return "Investmarkets";
        case "invesacapital":
            return "InvesaCapital";
        case "inefex":
            return "Inefex";
        case "obrinvest":
            return "Obrinvest";
        case "patronfx":
            return "PatronFX";
        case "liquidityx":
            return "LiquidityX";
        case "investico":
            return "Investico";
        case "bigmarkets":
            return "BigMarkets";
        case "bullmarkets":
            return "BullMarkets";
        case "Market10":
            return "market10";
        case "fxonet":
            return "fxonet";
        case "proxtrend":
            return `proxtrend`;
        default:
            return brand ? capitalizeFirstLetter(brand) : "";
    }
};

export const getBrandIconPath = (mode: string): string => {
    return `${`/images/brand-logos/`}${getBrandIconName(mode)}`;
};

export const wait = (ms: number): Promise<number> => new Promise(resolve => setTimeout(resolve, ms));

interface IParams {
    retries: number;
    delay?: number;
    required?: string;
}

export const retryPromise = async (callback: () => Promise<any>, params: IParams): Promise<any> => {
    try {
        if (params.retries <= 0) {
            return callback();
        }

        const response = await callback();

        const isValidResponse = !!(
            response &&
            !response?.isFailed &&
            (params.required ? response[params.required] : true)
        );

        if (isValidResponse) {
            return response;
        }

        await wait(params.delay);

        return retryPromise(callback, { delay: params.delay, retries: params.retries - 1 });
    } catch (err) {
        return retryPromise(callback, { delay: params.delay, retries: params.retries - 1 });
    }
};

export function getDirectionsTranslationKey(type: string): string {
    if (!type) {
        return "";
    }

    const typeLowerCase = type.toLowerCase();
    let translationKey = "";

    if (["buy", "sell", "credit", "balance"].includes(typeLowerCase)) {
        translationKey = typeLowerCase;
    }

    if (!translationKey) {
        if (typeLowerCase.startsWith("buy")) {
            const [_, secondPart] = typeLowerCase.split("buy");
            translationKey = `buy_${secondPart}`;
        } else if (typeLowerCase.startsWith("sell")) {
            const [_, secondPart] = typeLowerCase.split("sell");
            translationKey = `sell_${secondPart}`;
        }
    }

    return translationKey ? `actionsId.${translationKey}` : type;
}

interface IIconPathParams {
    name: string;
    ext?: "svg" | "png";
    themeMode?: "light" | "dark";
}

const noSuffixIcons = ["danger"];

export const getIconPath = (params: IIconPathParams): string => {
    const { name, ext = "svg", themeMode } = params;

    if (themeMode && !noSuffixIcons.includes(name)) {
        return `/images/${themeMode === "dark" ? name : `${name}_light`}.${ext}`;
    }

    return `/images/${name}.${ext}`;
};

export const getErrorMessageTranslation = (errorMessage: string, t: (key: string) => string): string => {
    if (!errorMessage) {
        return "";
    }

    const key = `${errorMessage}`.trim().toLowerCase();

    switch (key) {
        case "trade is disabled":
            return t("messages.133");
        case "cannot authenticate user":
            return t("messages.Err61");
        case "invalid parameters":
            return t("messages.3");
        case "trader in professional group but tries retail asset":
            return t("messages.Err62");
        default:
            return errorMessage;
    }
};

export const assignPriceParamsToOrder = ({ ask, bid, symbolInfo, originalObject }) => {
    const additionalArgs = {
        seenAskPrice: getSeenPrice({ operation: "Buy", symbolInfo }),
        seenBidPrice: getSeenPrice({ operation: "Sell", symbolInfo }),
    };

    return {
        ...originalObject,
        ...additionalArgs,
    };
};

// To make it works correctly you need to add a wrapper to popup component
// that will contain
// const [listening, setListening] = useState(false)
// <div ref={menuRef}>  {  isOpen &&  <... your popup component> }  </div>
// as an example you can check <Calculate /> or <TradeAlerts />
// elementId is id for button that will open/close popup if it will need to be toggled by button

export function listenForOutsideClicks(
    listening,
    setListening,
    menuRef,
    toggler,
    elementId,
    closeButtonId
) {
    return () => {
        if (listening) return;
        if (!menuRef?.current) return;
        setListening(true);
        [`click`, `touchstart`].forEach(type => {
            document.addEventListener(`click`, e => {
                const current = menuRef?.current;
                const node = e?.target;
                const isOpen =
                    //@ts-ignore
                    node?.id !== closeButtonId && (node?.id === elementId || current?.contains(node));
                toggler(isOpen);
            });
        });
    };
}

// REQUEST_PLACED
export const processedQuedOrdersString = {
    getLast: () => {
        if (localStorage.getItem("queueOrderTime")) {
            const storageData = JSON.parse(localStorage.getItem("queueOrderTime"));
            if (!storageData) return null;
            return Array.isArray(storageData) ? storageData[storageData.length - 1] : storageData;
        }
        return null;
    },
    put: () => {
        if (localStorage.getItem("queueOrderTime")) {
            const quedOrders = JSON.parse(localStorage.getItem("queueOrderTime"));
            const currentTime = Date.now();

            if (Array.isArray(quedOrders)) {
                localStorage.setItem(
                    "queueOrderTime",
                    JSON.stringify([...quedOrders, currentTime.toString()])
                );
            } else {
                localStorage.setItem("queueOrderTime", JSON.stringify([currentTime.toString()]));
            }

            return null;
        } else {
            const currentTime = Date.now();
            localStorage.setItem("queueOrderTime", JSON.stringify([currentTime.toString()]));
            return null;
        }

        return null;
    },
    deleteFirst: () => {
        //FILO - first in last out
        if (localStorage.getItem("queueOrderTime")) {
            const quedOrders = JSON.parse(localStorage.getItem("queueOrderTime"));

            if (Array.isArray(quedOrders) && quedOrders.length > 1) {
                localStorage.setItem(
                    "queueOrderTime",
                    JSON.stringify(quedOrders?.slice(1, quedOrders.length))
                );
            } else {
                localStorage.removeItem("queueOrderTime");
            }

            return null;
        }

        return null;
    },
    delete: () => {
        localStorage.removeItem("queueOrderTime");
        return;
    },
};

export const isV5 = (): boolean => getLocationParam("v5") === "1";
